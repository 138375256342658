import React from "react"
import styled from "styled-components"
import { vw } from "../../../utilities/pxtovw"
import { StaticImage } from "gatsby-plugin-image"
import Chevron from "../../shared/chevron"
import Pattern from "../../shared/pattern"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const Wrap = styled.figure`
  height: ${vw(380)};
  width: ${vw(380)};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70%;
    height: 2.875rem;
    z-index: 1;
    background: var(--c-blue-101);
  }

  .xeno-chevy {
    width: ${vw(135)};
    height: ${vw(220)};
    top: 10%;
    transform: scale3d(-1, 1, 1);
    left: -15%;
  }

  .xn-pattern {
    width: 2.875rem;
    height: 2.875rem;
    bottom: 0;
    left: 50%;
    position: absolute;
    z-index: 2;
  }

  @media (max-width: 766px) {
    width: 50vw;
    height: 50vw;
    margin-left: 50vw;
  }
`

const Ele = () => {
  const heroImg = useStaticQuery(graphql`
    query savingImage {
      file(relativePath: { eq: "shutterstock_1436902832_decwxg.jpeg" }) {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Wrap>
      <GatsbyImage image={getImage(heroImg.file.childImageSharp)} alt="" />
      <Chevron />
      <Pattern type="dot" size="4" />
    </Wrap>
  )
}
export default Ele
