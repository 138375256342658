import React from "react"
import styled from "styled-components"

const Box = styled.main`
  position: relative;

  @media screen and (min-width: 1912px) {
  }
`

const Wrap = ({ children }) => {
  return <Box>{children}</Box>
}

export default Wrap
