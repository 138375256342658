import React from "react"
import styled from "styled-components"
import { rem } from "../../utilities/pxtorem"

const Box = styled.div`
  p {
    font-family: var(--xn-font-body);
    font-style: normal;
    font-weight: normal;
    line-height: ${rem(30)};
  }
`
const Ele = ({ children }) => {
  return <Box>{children}</Box>
}
export default Ele
