export const convertCurrency = (value) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(value)) >= 1.0e9
      ? parseFloat((Math.abs(Number(value)) / 1.0e9).toFixed(2)) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? parseFloat((Math.abs(Number(value)) / 1.0e6).toFixed(2)) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(value)) >= 1.0e3
      ? parseFloat((Math.abs(Number(value)) / 1.0e3).toFixed(2)) + "K"
      : Math.abs(Number(value))
}