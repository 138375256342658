import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  position: absolute;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Ele = ({ id }) => {
  return (
    <Box className={"xeno-chevy " + id}>
      <svg viewBox="0 0 207 338" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M195.969 195.856C199.494 192.337 202.284 188.153 204.177 183.549C206.071 178.944 207.03 174.01 206.999 169.033L206.999 168.404C207.036 163.428 206.076 158.495 204.176 153.894C202.276 149.293 199.474 145.118 195.936 141.614L64.4663 10.3091C57.3124 3.55904 47.8035 -0.138085 37.9618 0.00393805C28.1201 0.145961 18.7222 4.11597 11.7667 11.0697C4.81114 18.0234 0.846816 27.4122 0.71678 37.2393C0.586744 47.0664 4.30128 56.5563 11.0704 63.6911L116.107 168.768L11.0704 273.614C7.56217 277.115 4.77884 281.271 2.87938 285.846C0.979922 290.421 0.00154349 295.324 2.13328e-06 300.277C-0.00153922 305.229 0.973802 310.133 2.87042 314.709C4.76705 319.285 7.54778 323.443 11.0538 326.946C14.5598 330.449 18.7225 333.228 23.3042 335.125C27.8858 337.021 32.7968 337.998 37.7566 338C42.7164 338.002 47.6279 337.028 52.2108 335.134C56.7937 333.24 60.9581 330.464 64.4663 326.963L195.969 195.856Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="207"
            y1="169"
            x2="95.5653"
            y2="246.182"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00E2C1" />
            <stop offset="1" stopColor="#00E2C1" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}
export default Ele
