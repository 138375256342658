import React from "react"
import styled from "styled-components"
import Overline from "../../shared/overline"
import Flex from "../../shared/flex"
import Text from "../../shared/text"
import Title from "../../shared/title"
import { vw } from "../../../utilities/pxtovw"
import Bubbles from "./bubbles"
import Icon from "../../shared/icon"

const Box = styled.div`
  position: relative;
  padding-bottom: ${vw(97.73)};

  @media (max-width: 766px) {
    padding-bottom: 4rem;
  }
`

const Intro = styled.div`
  width: ${vw(434)};
  @media (max-width: 766px) {
    width: auto;
  }
`

const Buttons = styled.div`
  padding-top: ${vw(56)};

  a,
  button {
    cursor: pointer;
    color: var(--c-white);
    margin-right: 2.5rem;
    display: inline-block;

    &:hover {
      span {
        color: var(--c-mint-100);
      }

      svg path {
        fill: #ffff;
      }
    }
  }

  span {
    text-transform: uppercase;
    font-size: 0.6875rem;
    margin-left: 0.125rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: white;
    transition: color 0.3s;
  }

  .icon {
    width: 2.625rem;
    height: 2.625rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 40%;
      height: 40%;
    }

    path {
      fill: var(--c-mint-100);
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      border-radius: 100%;
      background: linear-gradient(
        148.87deg,
        #00e2c1 7.84%,
        rgba(0, 226, 193, 0) 50.91%
      );
      opacity: 0.2;
      z-index: -1;
    }
  }

  @media (max-width: 766px) {
    padding-top: 2rem;
  }
`

const Ele = () => {
  return (
    <Box>
      <Intro>
        <Bubbles />
        <Overline>Guidance</Overline>
        <Title>
          <h2>
            Speak to an <span>Advisor</span>
          </h2>
        </Title>

        <Text>
          <p>
            Explore answers to questions most customers ask and learn how XENO
            can help you plan, save and invest for your financial goals.
          </p>
        </Text>

        <Buttons>
          <a
            href="https://web.whatsapp.com/send?phone=256392177488&text&app_absent=0"
            rel="noreferrer"
            target="_blank"
            id="support__ask_a_question_link"
          >
            <Flex center>
              <div className="icon">
                <Icon title="faq-question" />
              </div>
              <span>Ask a Question</span>
            </Flex>
          </a>
          <a
            href="https://learn.myxeno.com/support"
            rel="noreferrer"
            target="_blank"
            id="support__more_answers_link"
          >
            <Flex center>
              <div className="icon">
                <Icon title="flag" />
              </div>
              <span>More Answers</span>
            </Flex>
          </a>
          {/* <button>
            <Flex center>
              <div className="icon">
                <Icon title="camera" />
              </div>
              <span>Request a Meeting</span>
            </Flex>
          </button> */}
        </Buttons>
      </Intro>
    </Box>
  )
}
export default Ele
