import React from "react"
import styled from "styled-components"
import Header from "./header"
import Faqs from "./faqs"

const Box = styled.section`
  position: relative;
  padding: 3vw 5rem 10vw 5rem;
  z-index: 0;
  color: var(--c-white);

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 1vw;
    width: 100%;
    height: 100%;
    background-color: var(--c-blue-101);
    transform: skewY(6deg);
    z-index: -1;
  }
  @media (max-width: 766px) {
    padding: 2rem;
  }
`

const Ele = () => {
  return (
    <Box>
      <Header />
      <Faqs />
    </Box>
  )
}

export default Ele
