import React from "react"
import styled, { css } from "styled-components"
import { vw } from "../../../utilities/pxtovw"
import { StaticImage } from "gatsby-plugin-image"

const Box = styled.div`
  position: absolute;
  width: ${vw(290)};
  height: ${vw(290)};
  left: 50%;
  bottom: 30%;
  @media (max-width: 766px) {
    position: relative;
    bottom: auto;
    width: 60vw;
    left: auto;
    height: 50vw;
    margin: 2rem auto 3rem;
  }
`

const Item = styled.div`
  position: absolute;

  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 4rem;
    height: 4rem;
    z-index: 1;
    top: -5%;
    left: -5%;
    border-radius: 100%;
    background: linear-gradient(
      209.09deg,
      #01ceb1 19.34%,
      rgba(1, 206, 177, 0) 83.66%
    );
  }

  ${props =>
    props.left &&
    css`
      top: 10%;
      left: 0%;
      width: 50%;
    `}

  ${props =>
    props.right &&
    css`
      bottom: 0;
      right: 0;
      width: 68%;
    `}

  ${props =>
    props.top &&
    css`
      top: 0;
      right: 10%;
      width: 35%;
    `}
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  border-radius: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`

const Ele = () => {
  return (
    <Box>
      <Item top>
        <Image>
          <StaticImage
            src="https://res.cloudinary.com/xeno-technologies/image/upload/c_fill,f_auto,h_333,q_auto:good,w_500/v1609834529/shutterstock_1768739324_wz1tj4.jpg"
            alt="Two men using a phone"
            loading="eager"
            backgroundColor="#1a2b35"
          />
        </Image>
      </Item>
      <Item left>
        <Image>
          <StaticImage
            src="https://res.cloudinary.com/xeno-technologies/image/upload/c_fill,f_auto,h_333,q_auto:good,w_500/v1609843153/shutterstock_1838712622_recolor_wn2ppg.jpg"
            alt="XENO Man and woman smiling while looking at phone"
            loading="eager"
            backgroundColor="#1a2b35"
          />
        </Image>
      </Item>
      <Item right>
        <Image>
          <StaticImage
            src="https://res.cloudinary.com/xeno-technologies/image/upload/c_scale,h_267,w_400/v1629552117/corporate-ugandans.f68d36fa7e8bc98463a6_h66eem.jpg"
            alt="Two XENO ladies using a tablet"
            loading="eager"
            backgroundColor="#1a2b35"
          />
        </Image>
      </Item>
    </Box>
  )
}
export default Ele
