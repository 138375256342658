import React from "react"
import styled from "styled-components"
import Overline from "../../shared/overline"
import { rem } from "../../../utilities/pxtorem"
import Icon from "../../shared/icon"
import FadeIn from "../../shared/fade-up-inview"

const Box = styled.li`
  padding-bottom: ${rem(72)};
  span {
    color: var(--c-mint-100);
    opacity: 1;
  }

  p {
    padding-top: ${rem(24)};
    padding-right: 1.25rem;
    line-height: 1.6;
  }

  @media screen and (max-width: 766px) {
    &:last-child {
      padding-bottom: 0;
    }

    p {
      font-size: 0.875rem;
      padding-right: 1.5rem;
    }
  }
`

const IconWrap = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  margin-bottom: ${rem(18)};
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    bottom: 0;
    left: 0;
    border-radius: 100%;
    background: linear-gradient(
      148.87deg,
      #00e2c1 7.84%,
      rgba(0, 226, 193, 0) 50.91%
    );
    opacity: 0.2;
    z-index: -1;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

const Ele = ({ icon, label, caption }) => {
  return (
    <Box>
      <FadeIn>
        <IconWrap>
          <Icon title={icon} />
        </IconWrap>
        <Overline>{label}</Overline>
        <p>{caption}</p>
      </FadeIn>
    </Box>
  )
}
export default Ele
