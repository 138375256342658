import * as React from "react"
import styled from "styled-components"
import Flex from "./flex"
import Icon from "./icon"

const Box = styled.div`
  position: relative;

  button {
    border: solid 1px var(--c-mint-100);
    border-radius: 100%;
    width: 3.75rem;
    height: 3.75rem;
    margin: 0 2.0625rem;
    transition: opacity 0.3s;
    position: relative;
    overflow: hidden;

    &:hover {
      &:after {
        transform: none;
        opacity: 1;
      }

      path {
        stroke: var(--c-blue-200);
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--c-mint-100);
      z-index: 0;
      border-radius: 100%;
      opacity: 0;
    }

    > div {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    &.reverse {
      transform: scale3d(-1, 1, 1);
    }

    svg {
      width: 1.25rem;
      height: 0.875rem;
    }
  }

  path {
    stroke: var(--c-mint-100);
  }

  @media (prefers-color-scheme: light) {
    path {
      stroke: var(--c-green-200);
    }
  }

  @media (max-width: 766px) {
    display: none;
  }
`

const Pages = styled.div`
  > div {
    width: 100%;
    height: 100%;
  }
  span {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    margin: 0 0.25rem;
    background-color: #585d6e;
    display: block;

    @media (prefers-color-scheme: light) {
      opacity: 0.25;
    }

    &.is-active {
      background-color: var(--c-mint-100);

      @media (prefers-color-scheme: light) {
        opacity: 1;
        background-color: var(--c-green-200);
      }
    }
  }
`

const Ele = ({ onNext, onBack, count, active }) => {
  let pages = []
  for (let i = 0; i < count; i++) {
    pages.push(i)
  }

  const handleNext = () => {
    onNext()
  }

  const handleBack = () => {
    onBack()
  }

  return (
    <>
      {count > 1 && (
        <Box>
          <Flex middle>
            <button onClick={() => handleBack()} className="reverse">
              <Flex center middle>
                <Icon title="long-arrow-ltr" />
              </Flex>
            </button>
            <Pages>
              <Flex center>
                {pages &&
                  pages.map((ele, index) => {
                    return (
                      <span
                        key={index}
                        className={index === active - 1 ? "is-active" : ""}
                      ></span>
                    )
                  })}
              </Flex>
            </Pages>
            <button onClick={() => handleNext()}>
              <Flex center middle>
                <Icon title="long-arrow-ltr" />
              </Flex>
            </button>
          </Flex>
        </Box>
      )}
    </>
  )
}
export default Ele
