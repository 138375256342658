import React from "react"
import styled, { css } from "styled-components"
import Overline from "../shared/overline"
import { vw } from "../../utilities/pxtovw"
import { useGlobalStateContext } from "../../utilities/context"

const Box = styled.div`
  color: var(--c-white);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0;

  @media (max-width: 766px) {
    padding: 1rem;
  }
`

const Row = styled.div`
  width: 50%;
  padding: 1rem ${vw(36)};
  padding-bottom: 1rem;

  span {
    letter-spacing: 0;
  }

  .value {
    font-weight: 500;
    letter-spacing: -0.01rem;

    &.highlight {
      font-weight: bold;
      color: var(--c-mint-100);
    }
  }

  ${props =>
    props.full &&
    css`
      width: 100%;
      padding-bottom: 0.5rem;
    `}

  ${props =>
    props.right &&
    css`
      text-align: right;
    `}
`

const Ele = ({ deposits, mostLikely, bestCase, worstCase }) => {
  const { investmentYears } = useGlobalStateContext()
  return (
    <Box>
      <Row full>
        <div className="header">
          <Overline>Projection</Overline>
        </div>
      </Row>
      <Row>
        <Overline>Your Deposits</Overline>
        <span className="value">{deposits}</span>
      </Row>
      <Row right>
        <Overline>Most Likely</Overline>
        <span className="value highlight">{mostLikely}</span>
      </Row>
      <Row>
        <Overline>Best Case</Overline>
        <span className="value">{bestCase}</span>
      </Row>
      <Row right>
        <Overline>Worst Case</Overline>
        <span className="value">{worstCase}</span>
      </Row>
    </Box>
  )
}
export default Ele
