import React from "react"
import styled from "styled-components"
import Copy from "./copy"
import Hero from "./hero"
import Flex from "../../shared/flex"

const Box = styled.section`
  position: relative;
  padding-top: 15vw;
  color: var(--c-white);

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 10vw;
    width: 100%;
    height: 100%;
    background-color: var(--c-blue-101);
    transform: skewY(6deg);
    z-index: -1;
  }

  @media (max-width: 766px) {
    > div {
      flex-direction: column-reverse;
    }
  }
`

const Ele = () => {
  return (
    <Box>
      <Flex edges>
        <Copy />
        <Hero />
      </Flex>
    </Box>
  )
}
export default Ele
