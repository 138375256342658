import * as React from "react"
import styled, { css, keyframes } from "styled-components"
import Faq from "../../shared/faq"
import Pager from "../../shared/pager"
import { vw } from "../../../utilities/pxtovw"
import { useFaqsData } from "../../../data/faqs"
import { questions } from "./data"

const fadeInLeft = keyframes`
  0%{
    opacity: 0;
    transform: translate3d(2rem, 0, 0); 
  }

  100% {
    opacity: 1; 
    transform: none; 
  }
`

const Box = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 3vw;

  li {
    padding-right: ${vw(72)};
    display: none;
    width: 33.3333%;

    &.is-active {
      display: block;
      animation: ${fadeInLeft} 0.5s ease both;
    }
  }

  ${props =>
    props.active &&
    css`
      display: flex;
    `}

  @media (max-width: 766px) {
    flex-wrap: wrap;

    li {
      padding-right: 0;
      width: 100%;
    }
  }
`



const Ele = () => {
  const [page, setPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(3)
  const [pageSize, setPageSize] = React.useState(3)

  const goBack = () => {
    if (page > 1) {
      setPage(page - 1)
    } else {
      setPage(pageCount)
    }
  }

  const goNext = () => {
    if (page < pageCount) {
      setPage(page + 1)
    } else {
      setPage(1)
    }
  }

  React.useEffect(() => {
    setPageCount(Math.ceil(questions.length / pageSize))
    setPage(1)
    if(window.innerWidth < 766){
      setPageSize(questions.length)
    }
  }, [pageSize])

  //data
  const popularFaqs = []
  useFaqsData().forEach((item) => {
    if(item.node.data.category === 'Popular Questions'){
      popularFaqs.push(item)
    }
  })

  return (
    <>
      <Box>
        {popularFaqs &&
          popularFaqs.map((item, key) => {
            let node = item.node.data
            return (
              <li
                className={
                  key >= pageSize * (page - 1) && key <= page * pageSize - 1
                    ? "is-active"
                    : ""
                }
                key={key}
                style={{
                  animationDelay: Math.abs(page - key) / 20 + "s",
                }}
              >
                <Faq
                  title={node.title.text}
                  caption={node.description.text.substring(0, 72)+"..."}
                  content={node.description.html}
                />
              </li>
            )
          })}
      </Box>
      <Pager
        onBack={() => goBack()}
        onNext={() => goNext()}
        count={pageCount}
        active={page}
      />
    </>
  )
}
export default Ele
