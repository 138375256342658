import React from "react"
import styled from "styled-components"
import { vw } from "../../../utilities/pxtovw"
import Bkg from "../../shared/chevron-bkg"
import Copy from "./copy"
import Hero from "./hero"

const Col = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  padding-top: 5rem;

  @media (max-width: 766px) {
    display: block;
    padding-top: 0;
    width: auto;
    height: auto;
    padding-bottom: 5rem;
  }
`
const Box = styled.section`
  position: relative;
  padding-bottom: ${vw(70)};

  .chevron-bkg {
    width: 90%;
    height: 115%;
    z-index: -1;
    top: 1%;
    left: 0;
  }

  @media (prefers-color-scheme: light) {
    .chevron-bkg {
      opacity: 0.075;
    }
  }

  @media (max-width: 766px) {
    .chevron-bkg {
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  @media (max-width: 766px) and (prefers-color-scheme: light) {
    .chevron-bkg {
      opacity: 0.05;
    }
  }
`

const Splash = () => {
  return (
    <Box>
      <Col>
        <Copy />
        <Hero />
      </Col>
      <Bkg />
    </Box>
  )
}

export default Splash
