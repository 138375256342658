import React, { useEffect, useRef, useState } from "react"
import Overline from "../shared/overline"
import styled from "styled-components"
import noUiSlider from "nouislider"
import wNumb from "wnumb"
import "nouislider/dist/nouislider.css"
import { useGlobalDispatchContext } from "../../utilities/context"

const Box = styled.div`
  padding-bottom: 2.5rem;
  color: var(--c-blue-200);

  .xn-overline {
    color: var(--c-blue-200);
  }

  &.last-child {
    padding-bottom: 0;
  }

  .noUi-target {
    border: none;
  }

  .noUi-horizontal {
    height: 4px;
  }

  .noUi-connect {
    background-color: #fff;
  }

  .noUi-connects {
    background-color: var(--c-blue-200);
  }

  .noUi-handle {
    border: none;
    box-shadow: none;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    top: -0.9125rem;

    &:before,
    &:after {
      display: none;
    }
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  align-items: center;

  > span {
    letter-spacing: 0;
    opacity: 1;
  }
`

const Value = styled.div`
  &.years {
    display: flex;
    flex-direction: row-reverse;

    i {
      padding-right: 0;
    }

    span {
      padding-right: 0.5rem;
    }
  }

  i,
  span {
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: -0.04rem;
    display: inline-block;
  }

  i {
    font-weight: normal;
    padding-right: 0.5rem;
  }
`

const Slider = ({ label, min, max, start, unit, step, className }) => {
  const control = useRef(null)
  const [value, setValue] = useState(start)
  const [formatted, setFormatted] = useState(0)

  const dispatch = useGlobalDispatchContext()

  //setup slider
  useEffect(() => {
    let ele = control.current

    if (typeof window !== "undefined") {
      noUiSlider.create(ele, {
        start: start,
        connect: [true, false],
        step: step,
        format: wNumb({
          decimals: 0,
        }),
        range: {
          min: min,
          max: max,
        },
      })
    }
  }, [min, max, start, step, label])

  //on slider update
  useEffect(() => {
    let slideEle = control.current.noUiSlider
    slideEle.on("update", function () {
      setValue(slideEle.get())

      //formatted value
      let Format = wNumb({
        thousand: ",",
        decimals: 0,
      })
      setFormatted(Format.to(parseInt(slideEle.get())))
    })
  })

  //update global state
  useEffect(() => {
    switch (label) {
      case "First Deposit":
        dispatch({
          type: "SET_FIRST_DEPOSIT",
          value: value,
        })
        break

      case "Monthly Deposit":
        dispatch({
          type: "SET_MONTHLY_DEPOSIT",
          value: value,
        })
        break

      case "Investment Period":
        dispatch({
          type: "SET_INVESTMENT_YEARS",
          value: value,
        })
        break

      default:
        break
    }
  }, [value, dispatch, label])

  return (
    <Box className={className}>
      <Title>
        <Overline>{label}</Overline>
        <Value className={unit}>
          {unit && <i>{unit}</i>}
          <span>{formatted}</span>
        </Value>
      </Title>
      <div ref={control}></div>
    </Box>
  )
}
export default Slider
