import React from "react"
import styled from "styled-components"
import Glyph from "../shared/icon"
import Chevron from "../shared/chevron"
import Flex from "../shared/flex"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const Fig = styled.figure`
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  overflow: hidden;
  position: relative;

  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Box = styled.button`
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;
  }

  > div {
    position: relative;
  }

  .icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.75rem;

    path {
      fill: var(--c-mint-100);
    }
  }

  span {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    margin-right: 0.75rem;
  }

  .xeno-chevy {
    height: 4.625rem;
    width: 2.4375rem;
    right: -1rem;
    top: -0.2rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media (prefers-color-scheme: light) {
    .icon {
      path {
        fill: var(--c-green-200);
      }
    }
  }
`

const Ele = ({ label, icon, src, dynamicImg, onClick, id }) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <Box
      id={id}
      onClick={() => {
        handleClick()
      }}
    >
      <Flex center>
        <div className="icon">
          <Glyph title={icon} />
        </div>
        <span>{label}</span>
        <Fig>
          {src && <img className="img" src={src} alt="" />}
          {dynamicImg && <GatsbyImage image={getImage(dynamicImg)} alt="" />}
        </Fig>
        <Chevron />
      </Flex>
    </Box>
  )
}
export default Ele
