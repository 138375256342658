import React, { useState } from "react"
import styled from "styled-components"
import Copy from "./copy"
import Hero from "./hero"
import Flex from "../../shared/flex"

const Box = styled.section`
  position: relative;
  padding-top: 8vw;
  padding-bottom: 8vw;
  margin-top: 3vw;

  > div {
    flex-direction: row-reverse;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 1vw;
    width: 100%;
    height: 100%;
    background-color: var(--c-blue-101);
    transform: skewY(6deg);
    z-index: -1;
  }

  @media (max-width: 766px) {
    margin-top: 0;
    padding-top: 5rem;
    > div {
      display: block;
    }

    &:after {
      top: 9vw;
    }
  }
`

const Ele = () => {
  const [index, setIndex] = useState(0)
  const handleClick = index => {
    setIndex(index)
  }

  return (
    <Box id="how-xeno-works">
      <Flex edges>
        <Copy setIndex={e => handleClick(e)} />
        <Hero index={index} />
      </Flex>
    </Box>
  )
}
export default Ele
