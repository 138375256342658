import React from "react"
import styled from "styled-components"
import { vw } from "../../utilities/pxtovw"
import Image from "./icon"
import Bkg from "./chevron-bkg"
import { useGlobalDispatchContext } from "../../utilities/context"

const Box = styled.article`
  position: relative;
  z-index: 1;
  overflow: hidden;

  .chevron-bkg {
    top: auto;
    left: 0;
    bottom: 0;
    width: 70%;
    height: 100%;
    z-index: 2;

    path {
      fill: var(--c-blue-200);
      opacity: 0.2;
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
  }

  &:after {
    background: linear-gradient(180deg, #00e2c1 0%, rgba(0, 226, 193, 0) 100%);
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:before {
    z-index: 1;
    background-color: var(--c-blue-101);
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
  }

  @media (max-width: 766px) {
    margin-bottom: 2rem;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 4;
  padding: ${vw(30)};

  h2 {
    font-size: 1.125rem;
    height: ${vw(48)};
    font-family: var(--xn-font-body);
    font-weight: 500;
    color: var(--c-mint-100);
    line-height: 1.5;
  }

  p,
  h2 {
    margin-bottom: ${vw(36)};
  }

  p {
    line-height: 1.8;
    font-size: 0.875rem;
  }

  button {
    text-transform: uppercase;
    color: var(--c-mint-100);
    font-weight: bold;
    font-size: 0.75rem;
    transition: color 0.3s;
    padding: 1rem 0;

    &:hover {
      color: var(--c-white);
    }
  }

  @media (max-width: 766px) {
    padding: 1.5rem;

    h2 {
      height: auto;
    }

    p,
    h2 {
      margin-bottom: 0;
      padding-bottom: 1.5rem;
    }
  }
`

const Icon = styled.div`
  width: ${vw(34)};
  height: ${vw(34)};
  margin-bottom: ${vw(30)};

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 766px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Ele = ({ title, caption, content }) => {
  const dispatch = useGlobalDispatchContext()
  const handleClick = () => {
    dispatch({
      type: "SET_SHOW_MODAL",
      show: true,
      content: {
        title: title,
        html: content,
      },
    })
  }
  return (
    <Box>
      <Content>
        <Icon>
          <Image title="faq-question" />
        </Icon>
        <h2>{title}</h2>
        <p>{caption}</p>
        <button onClick={() => handleClick()}>More +</button>
      </Content>
      <Bkg />
    </Box>
  )
}
export default Ele
