import React from "react"
import styled from "styled-components"
import Header from "./header"
import Planner from "../../planner/container"

const Box = styled.section`
  position: relative;
  padding: 16vw 5rem 17vw 5rem;

  .chevron-bkg {
    width: 50%;
    height: 100%;
    top: 5%;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 766px) {
    padding: 0;
    padding-top: 7rem;
    padding-bottom: 10rem;
  }
`

const Ele = () => {
  return (
    <Box id="time-machine">
      <Header />
      <Planner />
    </Box>
  )
}
export default Ele
