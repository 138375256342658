import * as React from "react"
import styled, { css } from "styled-components"
import Flex from "../../shared/flex"

const Box = styled.div`
  padding: 6.5vw 0 5vw;

  button {
    height: 2.2rem;
    position: relative;
    padding: 0 1rem;
    padding-left: 1.5rem;
    border: solid 1px var(--c-mint-100);
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    overflow: hidden;
    transition: color 0.3s;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--c-mint-100);
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s;
    }

    &:hover {
      &:after {
        opacity: 0.1;
      }
    }

    &.is-active {
      color: var(--c-blue-200);

      &:after {
        opacity: 1;
      }
    }

    &:first-child {
      border-radius: 5rem 0 0 5rem;
    }

    &:last-child {
      border-radius: 0 5rem 5rem 0;
      border-left: none;
    }
  }

  @media (max-width: 766px) {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    position: relative;
    top: 0;
    z-index: 0;
    background-color: rgb(13 16 22 / 96%);
  }

  @media (max-width: 766px) and (prefers-color-scheme: light) {
    background-color: var(--c-white);
  }
`

const Counter = styled.span`
  display: inline-block;
  padding: 0.5rem;
  background: var(--c-mint-100);
  border-radius: 100%;
  font-size: 0.5rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  color: #000;
  margin-left: 1rem;
  position: relative;
  z-index: 2;

  ${props =>
    props.active &&
    css`
      background-color: var(--c-blue-200);
      color: var(--c-white);
    `}
`

const types = [
  {
    label: "Individual",
    goals: 8,
  },
  {
    label: "Institution",
    goals: 4,
  },
]

const Ele = ({ onClick }) => {
  const [index, setIndex] = React.useState(0)
  const handleClick = key => {
    setIndex(key)
    onClick(key)
  }

  return (
    <Box>
      <Flex middle>
        {types &&
          types.map((ele, key) => {
            return (
              <button
                id={ele.label.toLowerCase() + "_tab"}
                key={key}
                onClick={() => handleClick(key)}
                className={index === key ? "is-active" : ""}
              >
                <Flex center>
                  {ele.label}{" "}
                  <Counter active={index === key ? true : false}>
                    {ele.goals}
                  </Counter>
                </Flex>
              </button>
            )
          })}
      </Flex>
    </Box>
  )
}
export default Ele
