import React from "react"
import styled, { keyframes } from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { vw } from "../../../utilities/pxtovw"
import Chevron from "../../shared/chevron"
import { graphql, useStaticQuery } from "gatsby"

const Box = styled.figure`
  width: ${vw(375)};
  height: ${vw(548)};
  position: sticky;
  margin-left: 12vw;
  top: 6vw;

  .xeno-chevy {
    width: ${vw(91)};
    height: ${vw(148)};
    z-index: 1;

    &.left {
      top: 20%;
      left: -2vw;
      transform: scale3d(-1, 1, 1);
    }

    &.right {
      bottom: 20%;
      right: 0;
    }
  }

  @media (max-width: 766px) {
    margin-left: 2rem; 
    position: relative;
    width: 65%;
    height: 80vw; 
    top: auto; 
    margin: 0 auto; 
  }
`



const fadeIn = keyframes`
  0% {
    opacity: 0; 
    transform: translate3d(1rem, 0, 0); 
  }

  100% {
    opacity: 1;
    transform: none;
  }
`

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: none;

  &.is-active {
    display: block;
    animation: ${fadeIn} 1s ease both;
  }
`

const Ele = ({ index }) => {
  const images = useStaticQuery(graphql`
    query imagesQuery {
      allFile(
        filter: { relativeDirectory: { eq: "flow" } }
        sort: { order: ASC, fields: absolutePath }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                formats: NO_CHANGE
                quality: 100
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  `)

  return (
    <Box>
      <Chevron id="left" />
      {images &&
        images.allFile.edges.map((item, key) => {
          return (
            <Wrap key={key} className={index === key ? "is-active" : ""}>
              <GatsbyImage
                image={getImage(item.node)}
                alt=""
                objectFit="contain"
                backgroundColor="rgba(0,0,0,0)"
              />
            </Wrap>
          )
        })}

      <Chevron id="right" />
    </Box>
  )
}
export default Ele
