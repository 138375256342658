import { getAnnualPortfolio } from "./get-annual-portfolio"

export const getAverageReturns = (
  monthlySavings,
  initialInvestment,
  startTime,
  averageRate,
  lowerRate,
  upperRate,
  investmentHorizon
) => {
  let resultArr = []
  for (let t = 0; t < investmentHorizon; t++) {
    let returnObj = { averageReturn: 0, lowerReturn: 0, upperReturn: 0 }
    returnObj.averageReturn = getAnnualPortfolio(
      monthlySavings,
      initialInvestment,
      1,
      t + 1,
      averageRate
    )
    returnObj.lowerReturn = getAnnualPortfolio(
      monthlySavings,
      initialInvestment,
      1,
      t + 1,
      lowerRate
    )
    returnObj.upperReturn = getAnnualPortfolio(
      monthlySavings,
      initialInvestment,
      1,
      t + 1,
      upperRate
    )
    resultArr[t] = returnObj
  }

  return resultArr
}
