import React from "react"
import styled from "styled-components"
import Overline from "../../shared/overline"
import Text from "../../shared/text"
import Title from "../../shared/title"
import ImageBtn from "../../shared/image-button"
import { vw } from "../../../utilities/pxtovw"
import { useGlobalDispatchContext } from "../../../utilities/context"
import { logEvent } from "../../../utilities/logevent"
import { useStaticQuery, graphql } from "gatsby"

const Box = styled.div`
  padding: 0 5rem;
  p {
    width: ${vw(434)};
  }

  @media (max-width: 766px) {
    padding: 2rem 2rem 3.5rem;
    p {
      width: auto;
    }
  }
`

const Intro = styled.div`
  width: ${vw(434)};

  @media (max-width: 766px) {
    width: auto;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 766px) {
    display: block;

    > div {
      padding-bottom: 3rem;
    }
  }
`

const Ele = () => {
  const dispatch = useGlobalDispatchContext()
  const showVideo = () => {
    dispatch({
      type: "SET_VIDEO",
      src: "https://www.youtube.com/embed/wlatuXknR0o?modestbranding=1&autoplay=1",
    })
    logEvent("Home", "Watch Video")
  }

  const buttonImg = useStaticQuery(graphql`
    query planningBtnImg {
      file(relativePath: { eq: "shutterstock_1436902832_decwxg.jpeg" }) {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Box>
      <Intro>
        <Overline invert>Planning</Overline>
        <Title invert>
          <h2>
            What do you want to <span>achieve?</span>
          </h2>
        </Title>
      </Intro>
      <Flex>
        <Text>
          <p>
            Start with a goal, get a plan, and set targets for that goal. XENO
            focuses on helping you achieve your desired goal.
          </p>
        </Text>
        <ImageBtn
          onClick={() => {
            showVideo()
          }}
          label="How XENO works"
          icon="play-filled"
          dynamicImg={buttonImg.file.childImageSharp}
        />
      </Flex>
    </Box>
  )
}
export default Ele
