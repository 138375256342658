import React from "react"
import styled from "styled-components"
import Header from "./header"
import List from "./list"
import { vw } from "../../../utilities/pxtovw"

const Box = styled.section`
  position: relative;
  padding-bottom: 4vw;
  padding-top: ${vw(100)};

  @media (max-width: 766px) {
    padding-top: 6rem;
  }
`

const Ele = () => {
  return (
    <Box id="goals">
      <Header />
      <List />
    </Box>
  )
}
export default Ele
