import * as React from 'react'
import styled from 'styled-components'

const Box = styled.div`
    position: absolute;

    svg {
        width: 100%;
        height: 100%;
    }
`

const Ele = () => {
    return (
      <Box className="chevron-bkg">
        <svg
          viewBox="0 0 490 799"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M463.251 462.986C471.584 454.665 478.179 444.776 482.655 433.892C487.131 423.007 489.399 411.344 489.326 399.578L489.326 398.092C489.413 386.329 487.144 374.666 482.653 363.791C478.162 352.915 471.539 343.046 463.173 334.763L152.392 24.3698C135.481 8.41322 113.003 -0.326419 89.738 0.00930917C66.4732 0.345038 44.2574 9.72976 27.8152 26.1677C11.373 42.6056 2.00171 64.7999 1.69433 88.0302C1.38696 111.261 10.1677 133.694 26.1693 150.56L274.465 398.952L26.1693 646.797C17.8762 655.073 11.2967 664.898 6.80652 675.713C2.31638 686.527 0.00357056 698.119 -6.1545e-05 709.825C-0.00369365 721.532 2.30191 733.126 6.78534 743.943C11.2688 754.76 17.8422 764.59 26.1301 772.87C34.418 781.151 44.2581 787.72 55.0888 792.203C65.9195 796.687 77.5285 798.996 89.253 799C100.978 799.004 112.588 796.702 123.421 792.225C134.255 787.748 144.099 781.184 152.392 772.909L463.251 462.986Z"
            fill="url(#paint1_linear)"
          />
          <defs>
            <linearGradient
              id="paint1_linear"
              x1="356.885"
              y1="399.711"
              x2="200.648"
              y2="701.873"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#141C2E" />
              <stop offset="1" stopColor="#141C2E" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    )} 
 export default Ele